import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/maintenance.jpg';

import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/website.jpg';
import similar2 from '../../assets/img/services/thumbnail/referencement.jpg';
import similar3 from '../../assets/img/services/thumbnail/copywriting.jpg';
import similar4 from '../../assets/img/services/thumbnail/developpement.jpg';
import SEO from '../../components/seo';

import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/maintenance.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Maintenance | Agence digitale | MDS Digital Agency"
      description="La maintenance d'un site internet, quels que soient sa taille et sa complexité, demeure indispensable pour son bon fonctionnement et sa sécurité."
      keywords="maintenance"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Maintenance</h2>
      <p>
        Votre <strong>site internet</strong> doit être <strong>entretenu</strong>,{' '}
        <strong>vérifié</strong> et <strong>mis à jour</strong> afin de toujours fonctionner de
        manière optimale et sécurisée. <strong>Vous éviterez </strong>
        ainsi que vos visiteurs soient confrontés à des <strong>
          problèmes de fonctionnement
        </strong>{' '}
        du site et vous vous prémunirez des attaques de <strong>hackers</strong>.
      </p>

      <p>MDS assure les opérations de maintenance indispensables pour votre site web:</p>
      <ul>
        <li>
          <strong>Nettoyage</strong> des fichiers temporaires et bases de données pour assurer un
          espace de fonctionnement suffisant,
        </li>
        <li>
          Correctifs de <strong>sécurité</strong> et mise à jour des systèmes exploités,
        </li>
        <li>
          <strong>Monitoring</strong> du site internet et des serveurs,
        </li>
        <li>
          Mise en place d’<strong>alertes</strong> de dysfonctionnement pour permettre une{' '}
          <strong>intervention immédiate</strong>.
        </li>
      </ul>
      <p>
        La maintenance d'un site internet, quels que soient sa taille et sa complexité, demeure
        indispensable pour son bon fonctionnement, sa sécurité et sa pérennité.
      </p>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Besoin de sécuriser votre site internet de manière optimale et pérenne?"
        subtitle="Faites appel à notre service de maintenance."
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Création de sites internet" url="/services/website/" img={similar1} />
          <SimilarCard text="Référencement" url="/services/referencement/" img={similar2} />
          <SimilarCard text="Copywriting" url="/services/copywriting/" img={similar3} />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
